<template>
    <section class="slot-reservation-section">
        <div class="slot-reservation-header">
            <div class="slot-reservation-icon" :style="{backgroundImage: `url('${require('@/assets/img/calendar.png')}')`}"></div>
            <div class="slot-reservation-header-content">
                <p v-if="selectedValidSlot != null">{{$t("slotBookedFor")}}</p>
                <p v-else>{{$t("bookSlotFor")}}</p>
                <div v-show="!showSlotsSelection" class="slots">
                    <div :class="{'slot': true, 'selected': selectedValidSlot != null}" @click="updateSlotsSelection(true)">
                        <span v-if="selectedSlotDay" class="text-capitalize">{{selectedSlotDay.day.name}}</span>
                    </div>
                    <div :class="{'slot': true, 'selected': selectedValidSlot != null}" @click="updateSlotsSelection(true)">
                        <span v-if="selectedSlot" >{{selectedSlot.slot}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showSlotsSelection" class="slot-reservation-content">
            <div class="slot-date-selection-wrapper">
                <span>{{$t("slotDay")}} :</span>
                <div class="slot-date-selection">
                    <span class="icon-fleche-gauche prev-slot"></span>
                    <div class="dates">
                        <div v-for="(day, id) in days" :key="id" :class="{'slot-date': true, 'selected-day': day.id == selectedDayId}" @click="selectDay(day.id)">
                            <span class="text-capitalize day">{{day.name}}</span>
                            <span class="month-day">{{day.day}}</span>
                        </div>
                    </div>
                    <span class="icon-fleche-droit next-slot"></span>
                </div>
            </div>
            <div v-if="selectedDaySlots.length != 0" class="slot-hour-selection-wrapper">
                <span>{{$t("slotHour")}} :</span>
                <div class="hours">
                    <div v-for="(slot, id) in selectedDaySlots" :key="id" :class="{'slot-hour-selection': true, 'slot-unavailable': !slot.isAvailable, 'selected-slot': id == selectedSlotId}" @click="selectSlot(slot, id)">
                        <span>{{slot.slot}}</span>
                    </div>
                </div>
                <span>
                <b-form-checkbox class="checkbox" id="checkbox-1" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                  {{$t("slotCheckbox")}}
                </b-form-checkbox>
              </span>
            </div>
            <button :class="{'footer-btn':true, 'translucide': !(this.selectedSlot && this.selectedSlot.isAvailable)}" @click="validSlot()">{{$t("confirmSlot")}}</button>
        </div>
    </section>
</template>
<script>
const moment = require("moment")
export default {
    data() {
        return {
            selectedDayId: 0,
            selectedSlotId: 0,
            showSlotsSelection: false,
            slots:[],
            selectedValidSlot: JSON.parse(localStorage.getItem("IEC_SELECTED_SLOT"))
        }
    },
    props:{
        disabled:{
            default: false,
            type: Boolean
        }
    },
    mounted() {
        moment.locale(this.$i18n.locale)
        for(let i = 0; i< 7; i++){
            let date = moment().add(i, 'd')
            this.slots.push({
                    id: i,
                    day:{
                        date: date,
                        name: moment(date).format("dddd"),
                        day: moment(date).format("D")
                    },
                    availableSlots:[
                        {
                            isAvailable: true,
                            slot: `10h00`
                        },
                        {
                            isAvailable: true,
                            slot: `11h00`
                        },
                        {
                            isAvailable: Math.random() > 0.5,
                            slot: `12h00`
                        },
                        {
                            isAvailable: Math.random() > 0.3,
                            slot: `13h00`
                        },
                        {
                            isAvailable: Math.random() > 0.5,
                            slot: `14h00`
                        },
                        {
                            isAvailable: true,
                            slot: `15h00`
                        }
                    ]
                })
        }
        if(this.selectedValidSlot != null){
            this.selectedDayId = this.selectedValidSlot.selectedDay.id
            this.selectedSlotId = this.selectedValidSlot.selectedSlotId
        }
        this.$emit("slotSelected", this.selectedValidSlot != null)
    },
    methods:{
        selectDay(dayId){
            this.selectedDayId = dayId
            this.selectedSlotId = 0
        },
        selectSlot(slot, id){
            if(slot.isAvailable){
                this.selectedSlotId = id
            }
        },
        validSlot(){
            this.selectedValidSlot = {
                selectedDay: this.slots.filter(slot => slot.id === this.selectedDayId)[0],
                selectedSlotId: this.selectedSlotId
            }
            localStorage.setItem("IEC_SELECTED_SLOT", JSON.stringify(this.selectedValidSlot))
            this.showSlotsSelection = false
            this.$emit("slotSelected", this.selectedValidSlot != null)
        },
        updateSlotsSelection(state){
            if(!this.disabled){
                this.showSlotsSelection = state
            }
        }
    },
    computed:{
        days(){
            return this.slots.map(slot => {
                return  {
                    id: slot.id,
                    name: slot.day.name,
                    day: slot.day.day
                }
            })
        },
        selectedDaySlots(){
            if(this.selectedDayId != -1){
                return this.slots.filter(slot => slot.id == this.selectedDayId)[0].availableSlots
            }
            return []
        },
        selectedSlotDay(){
            if(this.selectedDayId != -1){
                return this.slots.filter(slot => slot.id == this.selectedDayId)[0]
            }
            return null
        },
        selectedSlot(){
            if(this.selectedSlotDay && this.selectedSlotId != -1){
                return this.selectedSlotDay.availableSlots[this.selectedSlotId]
            }
            return null
        }
    }
}
</script>
<style>

.slot-reservation-section{
    box-shadow: #00000014 0px 3px 10px;
    margin: 16px;
    border-radius: 41.25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: 16px 16px;
    color: rgb(var(--hot-food-color));
    flex-direction: column;
}
.slot-reservation-section .slot-reservation-icon{
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    margin: 0 16px;
}
.slot-reservation-section .slot-reservation-header p{
    margin: 0;
}
.slot-reservation-section .slot-reservation-header{
    display: flex;
}
.slot-reservation-section .slot-reservation-header-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.slot-reservation-section .slot-reservation-header .slots{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 16px;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}
.slot-reservation-section .slot-reservation-header .slots .slot{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border: 1px solid;
    border-radius: 10px;
}
.slot-reservation-section .slot-reservation-header .slots .slot.selected{
    background: rgb(var(--hot-food-color));
    color: white;
}

.slot-reservation-content .dates{
    display: flex;
    margin: 0 8px;
    align-items: center;
    width: 90%;
    overflow-x: scroll;
    padding: 8px 0;
}

.slot-date{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0 4px;
    box-shadow: #00000014 0px 3px 10px;
    border-radius: 10px;
    color: gray;
    width: 80px;
    min-width: 80px;
}
.slot-date.selected-day, .slot-hour-selection.selected-slot{
    background: rgb(var(--hot-food-color));
    color: white;
}

.slot-date .month-day{
    font-weight: bold;
    font-size: 1.2rem;
}

.slot-date-selection{
    margin: 16px 0;
    display: flex;
    align-items: center;
}

.slot-reservation-content{
    width: 100%;
}
.slot-reservation-content .hours{
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
    display: grid;
    margin: 16px 0;
}
.slot-hour-selection{
    box-shadow: #00000014 0px 3px 10px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;    
    font-weight: bold;
    color: gray;
}
.slot-hour-selection.slot-unavailable{
    background: #00000014;
    color: #00000030;
}
.slot-reservation-content{
    display: flex;
    flex-direction: column;
}
.slot-reservation-content .footer-btn{
    align-self: center;
}
.checkbox{
  width: inherit;
  height: inherit;
  border: solid 0px;
  position: inherit;
}
</style>