<template>
    <div class="category-products">
        <div class="category-products-body">
            <SlotSelector @slotSelected="showFooter = $event"/>
            <section class="catalog-section">
                <CatalogProductBloc v-for="(product, id) in products" :key="id" :product="product" :productInSale="id % 3 == 0" @basketChanged="updateBasket()"/>
            </section>
        </div>
        <section v-if="showFooter" class="category-products-footer">
            <FooterComponent :key="footerRefreshKey" :isOffline="isOffline" askBeforeCheckout/>
        </section>
    </div>
</template>
<script>
    import CatalogProductBloc from "@/components/Food/CatalogProductBloc.vue"
    import FooterComponent from "@/components/Food/FooterComponent.vue"
    import SlotSelector from "@/components/Food/SlotSelector.vue"
    import {getProductsByCategory, getCategoryById, getNutriscore} from "@/assets/js/Catalog"
export default {
    props: ['isOffline'],
    components:{ CatalogProductBloc, SlotSelector, FooterComponent },
    data() {
        return {
                selectedDayId: 1,
                selectedSlotId: 0,
                products: [],
                category: null,
                showFooter: false,
                footerRefreshKey : 'products-footer' + parseInt(Math.random()*100),
                page:{
                    title: "Category",
                    description: null,
                    showBackBtn: true,
                    showFilterBtn: true
                }
        }
    },
    created() {
        let categoryId = this.$route.params.category
        if(categoryId){
            getCategoryById(categoryId, category => {
                this.category = category
                this.page.title = this.translateName(category.name)
                this.updatePageInfo()
                getProductsByCategory(categoryId).then(({status, data}) => {
                    if(status === 200){
                        this.products = data
                    }
                })
            })
        }
    },
    methods:{
        updateBasket(){
            this.$parent.basketChange();
            this.footerRefreshKey = 'home-footer' + parseInt(Math.random()*100)
        }
    },
    computed:{
        selectedDaySlots(){
            if(this.selectedDayId != -1){
                return this.slots.filter(slot => slot.id == this.selectedDayId)[0].availableSlots
            }
            return []
        },
        selectedSlotDay(){
            if(this.selectedDayId != -1){
                return this.slots.filter(slot => slot.id == this.selectedDayId)[0]
            }
        },
        selectedSlot(){
            if(this.selectedSlotId != -1){
                return this.selectedSlotDay.availableSlots[this.selectedSlotId]
            }
        }
    }
}
</script>
<style scoped>
.category-products .category-products-header{
    padding: 100px 16px 10px 16px;
    background: rgb(var(--hot-food-color));
    color: white;
    border-bottom-right-radius: 25px;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9;
}
.category-products .category-products-header h3{
    margin: 0 8px;
    font-weight: bold;
}
.category-products .category-products-header span{
    font-size: 1.6rem;
}
.category-products .category-products-header .filter-icon{
    margin-left: auto;
}

.home-header{
    padding: 100px 16px 4px 16px;
    background: rgb(var(--hot-food-color));
    color: white;
    border-bottom-right-radius: 25px;
}
.home-header p{
    font-size: 0.8rem;
}

.promos-section, .catalog-section{
    color: rgb(var(--hot-food-color));
    margin: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-items: stretch;
    align-items: stretch;
    padding-bottom: 64px;
}

.promos-section .promos-section-header, .catalog-section .catalog-section-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
}

.promos-section .promos-section-header h3, .catalog-section .catalog-section-header h3{
    margin: 0;
}

.promos-section .promos-section-header .divider, .catalog-section .catalog-section-header .divider{
    background: rgb(var(--hot-food-color));
    width: 68%;
    height: 1px;
    padding: 0 16px;
}
.promos{
    margin: 16px 0 8px 0;
}
.swiper-wrapper.flex{
    display: flex;
}
.category-products-footer{
    width: 100%;
    background: #ffffff75;
    position: fixed;
    bottom: 0;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
}
</style>
